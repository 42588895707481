import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/Index',
    component: () => import('@/views/Index.vue'),
    children: [
      { path: 'home', component: () => import('@/views/home/index.vue') },
      { path: 'room', component: () => import('@/views/board/room.vue') },
      { path: 'board/display/:id', component: () => import('@/views/board/display.vue'), props: true },
      { path: 'board/easy/:id', component: () => import('@/views/board/easy.vue'), props: true },
      { path: 'board/eff/:id', component: () => import('@/views/board/eff.vue'), props: true },
      { path: 'board/all/:id', component: () => import('@/views/shuju/App.vue'), props: true },
      { path: 'guanli/zhiji', component: () => import('@/views/guanli/zhiji.vue'), props: true },
      { path: 'chejian/set', component: () => import('@/views/chejian/index.vue'), props: true },
      { path: 'banci/set', component: () => import('@/views/banci/index.vue'), props: true },
      { path: 'shangzhou/list', component: () => import('@/views/shangzhou/index.vue'), props: true },
      { path: 'xiazhou/list', component: () => import('@/views/xiazhou/index.vue'), props: true },
      { path: 'jinshangzhou/list', component: () => import('@/views/jinzhoushangzhou/index.vue'), props: true },
      { path: 'jizu/set', component: () => import('@/views/jizu/index.vue'), props: true },
      { path: 'jizu/banci', component: () => import('@/views/jizu/banci.vue'), props: true },
      { path: 'user/set', component: () => import('@/views/user/index.vue'), props: true },
      { path: 'data/all', component: () => import('@/views/data/all.vue'), props: true },
      { path: 'data/jins', component: () => import('@/views/data/jins.vue'), props: true },
      { path: 'data/jitai', component: () => import('@/views/data/jitai.vue'), props: true },
      { path: 'data/creweff', component: () => import('@/views/data/creweff.vue'), props: true },
      { path: 'data/dangche', component: () => import('@/views/data/dangche.vue'), props: true },
      { path: 'data/dangyue', component: () => import('@/views/data/dangyue.vue'), props: true },
      { path: 'data/24h', component: () => import('@/views/data/24hover.vue'), props: true },
      { path: 'data/24hcrew', component: () => import('@/views/data/24hovercrew.vue'), props: true },
      { path: 'data/pingz', component: () => import('@/views/data/pingz.vue'), props: true },
      { path: 'data/chejian', component: () => import('@/views/data/chejian.vue'), props: true },
      { path: 'data/jinzhou', component: () => import('@/views/jinzhou/all.vue'), props: true },
      { path: 'data/ghjinz', component: () => import('@/views/genghuan/index.vue'), props: true },
      { path: 'data/stop', component: () => import('@/views/data/stop.vue'), props: true },
      { path: 'data/stopone', component: () => import('@/views/data/stopone.vue'), props: true },
      { path: 'data/easy', component: () => import('@/views/data/easy.vue'), props: true },
      { path: 'data/jixiu', component: () => import('@/views/data/jixiu.vue'), props: true },
      { path: 'data/sjszm', component: () => import('@/views/sjszm/index.vue'), props: true },
      { path: 'data/liaoji', component: () => import('@/views/data/liaoji.vue'), props: true },
      { path: 'data/dangbreeeff', component: () => import('@/views/data/dangchepingz.vue'), props: true },
      { path: 'data/dangbreeeffm', component: () => import('@/views/data/dangchepingzm.vue'), props: true },
      { path: 'data/zhiqu', component: () => import('@/views/data/zhiqu.vue'), props: true },
      { path: 'data/pais', component: () => import('@/views/data/userpai.vue'), props: true },
      { path: 'data/onem', component: () => import('@/views/data/dantai.vue'), props: true },
      { path: 'pingz/all', component: () => import('@/views/pingz/all.vue'), props: true },
      { path: 'jings/all', component: () => import('@/views/jings/all.vue'), props: true },
      { path: 'led', component: () => import('@/views/led/index.vue'), props: true },
      { path: '404', component: () => import('@/views/404.vue') },
      { path: 'object', component: () => import('@/views/yingy/index.vue') },
    ]
  },
  { path: '/404', component: () => import('@/views/404.vue') },
  { path: '/err', component: () => import('@/views/err.vue') },
  { path: '/board', component: () => import('@/views/sc-board/index.vue') },
  // { path: '*', redirect: '/404' }/
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
